<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      bottom
      left
      min-width="auto"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-text-field
          class="c-input-xs input-filter"
          :class="{'c-input-has-sort': hasSort}"
          :value="value"
          :label="label"
          :placeholder="placeholder"
          :prepend-inner-icon="sortPrependIcon"
          @click:prepend-inner="sortClick"
          @click:clear="clearAll"
          clearable
          hide-details
          outlined
          dense
          readonly
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title>
          <div class="d-flex">
            <v-text-field
                style="width: 90px"
                class="c-input-xs"
                v-model.trim="valueFrom"
                label="Từ"
                placeholder="Từ"
                clearable
                hide-details
                single-line
                outlined
                dense
                autofocus
                :disabled="disabledFilter"
                @keyup.enter="nextInput"
            ></v-text-field>
            <v-icon>mdi-arrow-right</v-icon>
            <v-text-field
                ref="refInputTo"
                style="width: 90px"
                class="c-input-xs"
                v-model.trim="valueTo"
                label="Đến"
                placeholder="Đến"
                clearable
                hide-details
                single-line
                outlined
                dense
                :disabled="disabledFilter"
                @keyup.enter="menu = null"
            ></v-text-field>
          </div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {debounce} from "lodash/function";

export default {
  name: 'InputFilterFromTo',
  props: {
    hasSort: {
      type: Boolean,
      default: () => false
    },
    name: {
      type: String,
      default: () => ''
    },
    sortName: {
      type: String,
      default: () => ''
    },
    sorting: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabledFilter: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    menu: null,
    // value: null,
    sortValue: null,
    valueFrom: null,
    valueTo: null,
  }),
  computed: {
    sortPrependIcon() {
      if (!this.hasSort) {
        return ''
      }
      switch (this.sortValue) {
        case 'asc':
          return 'mdi-arrow-up'
        case 'desc':
          return 'mdi-arrow-down'
        default:
          return 'mdi-arrow-up-down'
      }
    },
    value() {
      const val = []
      if (this.valueFrom) {
        val.push(this.formatNumber(this.valueFrom))
      }
      if (this.valueTo) {
        val.push(this.formatNumber(this.valueTo))
      }
      return val.join(' → ')
    }
  },
  watch: {
    value() {
      this.onFilter()
    },
    sorting(val) {
      if (val !== this.sortName) {
        this.sortValue = null
      }
    }
  },
  methods: {
    onFilter: debounce(function () {
      this.$emit('onFilter', {
        name: this.name,
        value: {
          from: this.valueFrom,
          to: this.valueTo,
        }
      })
    }, 500),
    sortClick() {
      if (!this.sortValue) {
        this.sortValue = 'asc'
      } else if (this.sortValue === 'asc') {
        this.sortValue = 'desc'
      } else if (this.sortValue === 'desc') {
        this.sortValue = 'asc'
      }
      this.$emit('onSort', {
        sort_by: this.sortName,
        sort_type: this.sortValue
      })
    },
    nextInput() {
      this.$refs.refInputTo.focus()
    },
    clearAll() {
      this.valueFrom = null
      this.valueTo = null
    }
  }
}
</script>
